.ant-layout-header, .ant-layout-sider, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #181717;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #131212;
}

.ant-layout-sider-trigger {
  background: #1d1c1c;
}

#root {
  width: 100%;
  height: 100%;
}
#root > div {
  width: 100%;
  height: 100%;
}
#root > div.app_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app_header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 4;
}

.container {
  width: 100%;
}
.container.container__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}

.app_loading_nflex {
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}

[for="upload_form_properties.copyright"], [for="edit_form_properties.copyright"] {
  font-weight: 600;
}

.grid-filter {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 12px 12px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 12px;
  max-width: 1280px;
}

.grid-filter.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-filter.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-item .grid--label {
  font-weight: 600;
  margin-bottom: 4px;
}

.sidebar .logo {
  background-color: rgba(0, 0, 0, 0.3);
}

.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.events .event {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.events .event + .event {
  border-top: 1px solid #dfdfdf;
}

.header--wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.header--wrapper .ant-page-header {
  background-color: rgba(255, 255, 255, 0);
  padding-left: 0;
}
.header--wrapper .ant-page-header span {
  color: #FFF;
}
.header--wrapper .ant-page-header .ant-page-header-back-button {
  color: #FFF;
}
.header--wrapper .header--user {
  display: flex;
  flex-direction: row;
}
.header--wrapper .header--user > div + div {
  margin-left: 12px;
}
.header--wrapper .header--user .user--name {
  color: rgba(255, 255, 255, 0.65);
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.groups--list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.groups--list .filter {
  background-color: #FFF;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.groups--list .filter > div + div {
  margin-left: 8px;
}
.groups--list .filter .search--column {
  width: 100%;
}
.groups--list .ant-spin-container {
  padding: 0 14px;
}
.groups--list .ant-spin-container > div {
  background-color: #FFF;
}

.users--list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.users--list .filter {
  background-color: #FFF;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.users--list .filter > div + div {
  margin-left: 8px;
}
.users--list .filter .search--column {
  width: 100%;
}
.users--list .ant-spin-container {
  padding: 0 14px;
}
.users--list .ant-spin-container > div {
  background-color: #FFF;
}

.permissions--list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.permissions--list .ant-table {
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.permissions--list .filter--permissions {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.permissions--list .filter--permissions > div + div {
  margin-left: 8px;
}
.permissions--list .filter--permissions .search--column {
  width: 100%;
}

.list--card {
  margin-bottom: 12px;
}
.list--card .header--authors a {
  border-right: 1px solid #dfdfdf;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 4px;
}
.list--card .header--authors a:last-child {
  border-right: 0;
}
.list--card.card--default {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #FFF;
  border-radius: 4px;
  margin-top: 12px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.list--card.card--default.card--vm {
  margin-top: 0;
}
.list--card.card--default.card--vm:first-child {
  margin-top: 12px;
}
.list--card.card--default .card--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: 1px solid #dfdfdf;
}
.list--card.card--default .card--actions > button + button {
  margin-top: 12px;
}
.list--card.card--default .card--info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list--card.card--default .card--info .header, .list--card.card--default .card--info .footer {
  padding: 4px;
}
.list--card.card--default .card--info .header {
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.list--card.card--default .card--info .header .header--info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list--card.card--default .card--info .header .header--status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list--card.card--default .card--info .header .header--status .date {
  margin-right: 10px;
}
.list--card.card--default .card--info .footer {
  border-top: 1px solid #dfdfdf;
}
.list--card.card--default .card--info .content {
  display: flex;
  flex-direction: row;
  padding: 8px;
}
.list--card.card--default .card--info .content .cover {
  display: flex;
  flex-direction: column;
  width: 240px;
}
.list--card.card--default .card--info .content .cover img {
  width: 100%;
  height: auto;
  border: 0;
}
.list--card.card--default .card--info .content .info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 12px;
}
.list--card.card--default .card--info .content .info .title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.list--card.card--default.card--vm .header--info {
  font-size: 13px;
}
.list--card.card--default.card--vm .header--status {
  font-size: 13px;
}
.list--card.card--default.card--vm .card--info .content .cover {
  width: 140px;
}
.list--card.card--default.card--vm .card--info .content .info .title {
  font-size: 16px;
  line-height: 20.8px;
}
.list--card.card--default.card--vm .card--info .content .info .info--row {
  font-size: 13px;
  line-height: 16.9px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list--card.card--default.card--vm .card--info .content .info .info--row > div {
  margin-right: 8px;
}
.list--card .footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list--card .footer .footer--row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.list--card .footer .footer--row.card--dates div + div {
  margin-left: 12px;
}
.list--card .footer .footer--row + .footer--row {
  margin-top: 6px;
}
.list--card .footer .footer--row .card--prop {
  display: flex;
  flex-direction: row;
}
.list--card .footer .footer--row .card--prop .ant-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list--card .footer .footer--row .card--prop .ant-tag > i {
  margin-right: 4px;
}

.tags {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.tags .label {
  padding-right: 8px;
  font-weight: 600;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form .form__layout-add {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.form .form__layout-edit {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.form .form__layout-edit .form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-right: 12px;
}
.form .form__layout-edit .form__options--container {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 320px;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 12px;
}
.form .form__layout-edit .form__options--container .form__options--wrapper {
  position: sticky;
  top: 10px;
}
.form .form__layout-edit .form__options--container .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form .form__layout-edit .form__options--container .actions > div {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form .form__layout-edit .form__options--container .actions > div > * + * {
  margin-left: 12px;
}
.form .form__layout-edit .form__options--container .actions > button {
  margin-bottom: 20px;
  width: auto;
}
.form .form__layout-edit .form__options--container .options--row {
  margin-top: 12px;
}
.form .coords--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.form .coords--wrapper > div {
  padding-left: 12px;
}
.form .map {
  width: 100%;
  min-height: 420px;
}

.options--row.anitplagiat--row > div {
  text-align: left;
}

.cross_link__list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cross_link__list .list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 4px;
  justify-content: space-between;
}
.cross_link__list .list__item + .list__item {
  border-top: 1px solid #dfdfdf;
}
.cross_link__list .list__item .list__item-actions {
  margin-left: 20px;
}

.users--edit {
  padding: 20px 12px 0 12px;
}
.users--edit .users--wrapper {
  background-color: #FFF;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
}

.group-add {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.group-add > .groups--form {
  background-color: #FFF;
  border-radius: 4px;
  padding: 8px;
}

.filter--article {
  background-color: #FFF;
  border-radius: 4px;
  padding: 8px;
  position: sticky;
  top: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.filter--article .filter--actions {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter--article .filter--actions > button + button {
  margin-left: 12px;
}
.filter--article .filter--options .filter--row + .filter--row {
  margin-top: 8px;
}

.app_view--filter .ant-spin-nested-loading {
  position: sticky;
  top: 70px;
}

.textarea {
  display: flex;
  flex-direction: row;
}
.textarea textarea {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.textarea .counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 4px;
}

.media-input {
  margin-bottom: 24px;
}
.media-input .media--preview {
  max-width: 230px;
}

.upload--input__form {
  display: flex;
  flex-direction: row;
}
.upload--input__form .form--preview {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 320px;
}
.upload--input__form .form--preview img {
  width: 100%;
  height: auto;
  border: 0;
}
.upload--input__form .form--preview video {
  width: 100%;
  max-height: 320px;
}
.upload--input__form .form--preview iframe {
  width: 100%;
  height: auto;
}
.upload--input__form .form {
  border-left: 1px solid #dfdfdf;
  margin-left: 12px;
  padding: 0 12px;
  width: 100%;
}

.pdf--selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e2e2e2;
  padding: 10px;
}
.pdf--selected > div {
  flex: 1;
  flex-direction: row;
}
.pdf--selected > div:last-child {
  text-align: right;
  justify-content: flex-end;
}

.upload--input {
  position: relative;
}
.upload--input .media__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.editor {
  position: relative;
}
.editor .editor--hidden {
  display: none;
}

.tox-statusbar__branding {
  opacity: 0;
}

.quest__form--wrapper {
  display: block;
}
.quest__form--wrapper .form__wrapper {
  display: BLOCK;
  background-color: #DFDFDF;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
}

.media--list {
  display: flex;
  flex-direction: column;
}
.media--list .search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.media--list .media--list_wrapper .pagination {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.media--list .media--list_wrapper .media--list_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.media--list .media--list_wrapper .media--list_container .media--card {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  margin-bottom: 12px;
  margin-right: 12px;
  min-width: 240px;
  max-width: 240px;
  position: relative;
}
.media--list .media--list_wrapper .media--list_container .media--card .image--usage {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgb(255, 0, 0);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #FFF;
}
.media--list .media--list_wrapper .media--list_container .media--card .image--expired {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgb(255, 0, 0);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #FFF;
}
.media--list .media--list_wrapper .media--list_container .media--card .media--preview {
  min-height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #dfdfdf;
}
.media--list .media--list_wrapper .media--list_container .media--card .media--info, .media--list .media--list_wrapper .media--list_container .media--card .media--actions {
  padding: 8px;
}

.media--selected {
  display: flex;
  flex-direction: column;
  max-width: 480px;
}
.media--selected img {
  width: 100%;
  height: auto;
}
.media--selected .media--actions {
  margin-top: 12px;
}

.doc--status {
  display: flex;
  flex-direction: column;
}
.doc--status .doc--status_row {
  display: block;
}
.doc--status .doc--status_row.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.doc--status .doc--status_row.status .label {
  margin-right: 4px;
}
.doc--status .doc--status_row + div {
  margin-top: 8px;
}

.history .history--wrapper {
  max-height: 240px;
  overflow-y: auto;
  padding-top: 10px;
}

.container--diff {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.container--diff > div {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-right: 20px;
}
.container--diff > div + div {
  padding-right: 0;
  padding-left: 20px;
  border-left: 1px solid #dfdfdf;
}
.container--diff > div + div .changed {
  width: 100%;
  background-color: #dfdfdf;
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.container--diff > div .cover {
  width: 100%;
  margin-bottom: 20px;
}
.container--diff > div .cover img {
  width: 100%;
  height: auto;
}
.container--diff > div .title {
  font-weight: 600;
  font-size: 2em;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 12px;
}
.container--diff > div .text--title {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 12px;
}
.container--diff > div .slug {
  margin: 4px 0;
}
.container--diff > div .options {
  background-color: #dfdfdf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 4px 0;
  padding: 4px;
}
.container--diff > div .options > div {
  border-bottom: 1px solid #e2e2e2;
  padding: 4px 0;
}
.container--diff > div .options > div:last-child {
  border-bottom: 0;
}
.container--diff .preview--block {
  width: 100%;
  margin: 12px 0;
}
.container--diff .preview--block > * {
  width: 100%;
}

.form--group.cover .media_selector.cover__selector {
  padding: 0;
}
.form--group.cover .form--group__title {
  margin-bottom: 0;
}
.form--group.cover .form--group__container {
  padding: 0;
}

.media--container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.media--container .media--filter {
  padding: 8px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.media--container .media--filter .filter {
  border-left: 1px solid #dfdfdf;
  margin-left: 12px;
  padding-left: 12px;
}
.media--container .media--list__pager {
  border-top: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 360px;
  line-height: 360px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-bottom: 12px;
  min-width: 23%;
  max-width: 23%;
  transition: boxShadow 0s ease 0.3s;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0);
  position: relative;
}
.item .photo {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #dfdfdf;
  position: relative;
}
.item .photo:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  transition: backgroundColor 0s ease 0s;
}
.item .info {
  font-size: 12px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item .actions {
  z-index: 2;
  position: absolute;
  top: -10px;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 8px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.item .actions > div + div {
  margin-left: 4px;
}

.item:hover {
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.4);
}
.item:hover .photo:after {
  background-color: rgba(0, 0, 0, 0.3);
}
.item:hover .actions {
  top: 0;
  opacity: 1;
}

.video-play {
  overflow: hidden;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 24%;
  padding-bottom: 24%;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.media--list__items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.media--list__items .ant-empty {
  margin: 12px 0;
}
.media--list__items .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 12px 12px 0 12px;
}

.uploader {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.uploader .uploader--options {
  padding: 8px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.uploader .uploader--container {
  padding: 8px;
}

.docs--widget {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.docs--widget .docs--container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
}
.docs--widget .docs--container .docs--search_filter {
  margin-bottom: 12px;
}

.docs--widget_item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}
.docs--widget_item .cover {
  display: flex;
  flex-direction: column;
  max-width: 140px;
  min-width: 140px;
  margin-right: 12px;
}
.docs--widget_item .cover img {
  width: 100%;
  height: auto;
  border: 0;
}
.docs--widget_item .options {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.docs--widget_item .options > div {
  display: flex;
}
.docs--widget_item .options > div.info {
  flex: 1;
  flex-direction: column;
}
.docs--widget_item .options .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 20.8px;
}
.docs--widget_item .options .title .ant-tag {
  margin-right: 0;
}
.docs--widget_item .options .summary {
  font-size: 14px;
  line-height: 18.2px;
  margin-bottom: 4px;
}
.docs--widget_item .options .published {
  margin-bottom: 4px;
}
.docs--widget_item .options .actions {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.docs--widget_item .options .actions > button + button {
  margin-top: 8px;
}

.widget_content--preview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px 4px;
}
.widget_content--preview.film {
  grid-template-columns: 1fr;
}
.widget_content--preview img {
  border-radius: 4px;
  width: 100%;
}

.document--lock {
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  align-items: center;
  justify-content: space-between;
  margin: 12px;
  padding: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.workflow {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboard-workflow .table--pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0;
}
.dashboard-workflow .table-cover {
  max-width: 120px;
}
.dashboard-workflow .table-cover img {
  max-width: 120px;
}
.dashboard-workflow .ant-table-thead > tr > th {
  font-size: 13px;
}
.dashboard-workflow .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
  font-size: 12px;
  position: relative;
}
.dashboard-workflow .wwstatus {
  width: 100%;
  height: 100%;
  background-color: #f5222d;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.workflow--table__statuses {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workflow--table__statuses .statuses--item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workflow--table__statuses .statuses--item + div {
  margin-left: 20px;
}
.workflow--table__statuses .statuses--item > div {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.ant-table-bordered .ant-table-tbody > tr > td.workflow_status--column {
  text-align: center;
}
.ant-table-bordered .ant-table-tbody > tr > td.workflow_status--column .ant-tag {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin-right: 0;
}

.workflow--table .ant-table-bordered .ant-table-tbody > tr > td {
  position: relative;
}
.workflow--table .ant-table-bordered .ant-table-tbody > tr > td .status_worflow_table {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 85, 0);
  position: absolute;
  top: 0;
  left: 0;
}
.workflow--table .ant-table-bordered .ant-table-tbody > tr > td .status_worflow_table.green {
  background-color: #87d068;
}

.workflow--title {
  display: inline-block;
  flex-direction: row;
  width: 100%;
}
.workflow--title .lock {
  margin-right: 8px;
  display: inline-block;
}

.dashboard--table {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dashboard--table .filter--checkbox {
  margin-left: 10px;
  max-width: 44%;
}
.dashboard--table .table--filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}
.dashboard--table .table--filter .filter--status {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.dashboard--table .table--filter .filter--status .status--item + .status--item {
  margin-left: 10px;
}
.dashboard--table .table--filter .filter--status span {
  padding-left: 4px;
}
.dashboard--table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.dashboard--table table tr:nth-child(odd) {
  background-color: rgba(226, 226, 226, 0.5);
}
.dashboard--table table tr td {
  border: 1px solid #dfdfdf;
  padding: 4px 6px;
  font-size: 12px;
  text-align: center;
}
.dashboard--table table tr td.wf-status {
  width: 30px;
  align-items: center;
  text-align: center;
}
.dashboard--table table tr td.created {
  width: 130px;
  text-align: center;
}
.dashboard--table table tr td.title {
  text-align: left;
}
.dashboard--table table tr td.title > * {
  display: inline;
}
.dashboard--table table tr td.title > *.lock {
  margin-right: 4px;
}
.dashboard--table table tr td.authors {
  width: 120px;
}
.dashboard--table table tr td.status {
  width: 30px;
  align-items: center;
  text-align: center;
}
.dashboard--table table tr td.status i svg {
  fill: #34c402;
}
.dashboard--table table tr td.status.bad i svg {
  fill: #ff3d00;
}
.dashboard--table table tr td.user-created {
  width: 130px;
}
.dashboard--table table tr td.cover {
  width: 80px;
  padding: 0;
}
.dashboard--table table tr td.cover .lightbox-img-thumbnail {
  margin: 0;
}
.dashboard--table table tr td.cover img {
  width: 80px !important;
  height: auto !important;
}
.dashboard--table table tr td img {
  max-width: 80px;
}

.insert--image_plg {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.insert--image_plg .actions {
  margin-bottom: 12px;
}

[role=tabpanel] .media--list {
  width: 100%;
  max-width: 100%;
}

.media--list {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #dfdfdf;
  border-radius: 4px;
  flex-wrap: wrap;
  max-width: 1000px;
  padding-top: 12px;
  justify-content: center;
}
.media--list .ant-pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}
.media--list .media--list_item {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 305px;
  min-width: 240px;
  width: 100%;
  background-color: #FFF;
  position: relative;
  margin: 6px;
}
.media--list .media--list_item .image--usage {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgb(255, 0, 0);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #FFF;
}
.media--list .media--list_item .cover--background {
  background-repeat: no-repeat;
  background-position: center center;
  height: 160px;
  background-size: cover;
}
.media--list .media--list_item .cover--background video {
  height: 100%;
  width: auto;
}
.media--list .media--list_item .cover--background iframe {
  width: 100%;
  height: auto;
}
.media--list .media--list_item .image--info {
  padding: 8px;
}
.media--list .media--list_item .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.media--selected_list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.media--selected_list .media--selected_item {
  display: flex;
  flex-direction: row;
  border: 1px solid #dfdfdf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 4px;
}
.media--selected_list .media--selected_item.type_video .cover {
  min-width: 240px;
  max-width: 240px;
}
.media--selected_list .media--selected_item.type_externalvideo .cover {
  min-width: 240px;
  max-width: 240px;
}
.media--selected_list .media--selected_item + .media--selected_item {
  margin-top: 12px;
}
.media--selected_list .media--selected_item .cover {
  display: inline-block;
  flex-direction: column;
  min-width: 160px;
  max-width: 160px;
  height: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.media--selected_list .media--selected_item .cover video {
  height: auto;
  width: 100%;
}
.media--selected_list .media--selected_item .cover iframe {
  height: auto;
  width: 100%;
}
.media--selected_list .media--selected_item .info {
  flex: 1;
  flex-direction: column;
  padding-right: 20px;
}
.media--selected_list .media--selected_item .actions {
  display: flex;
  flex-direction: column;
  width: 32px;
  align-items: center;
  justify-content: center;
}
.media--selected_list .media--selected_item .actions > button + button {
  margin-top: 10px;
}

.code--list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  max-height: 600px;
  overflow: auto;
}
.code--list .code--list_item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.code--list .code--list_item + .code--list_item {
  margin-top: 10px;
}
.code--list .code--list_item .code--preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  overflow-y: auto;
}
.code--list .code--list_item .code--actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px;
}
.code--list .code--list_item .code--actions button + button {
  margin-left: 8px;
}

.report--table .red {
  background-color: rgb(252, 108, 108);
}

.report--table + .report--table {
  margin-top: 20px;
}
.report--table .report--table__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.report--table .report--table__title .title {
  font-weight: 600;
  font-size: 1.3em;
}
.report--table .wrapper {
  margin: 20px;
  background-color: #FFF;
  border-radius: 4px;
}
.report--table .wrapper .image--preview {
  max-width: 120px;
  height: auto;
  width: 100%;
}

.report.widgets--report {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.report.widgets--report .form {
  width: 100%;
  background-color: #FFF;
  padding: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.report.widgets--report .widgets--list {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.report.widgets--report .widgets--list .widget--item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 33%;
  margin-bottom: 12px;
}
.report.widgets--report .widgets--list .widget--item .label {
  padding-left: 12px;
  padding-right: 8px;
}
.report.widgets--report .widgets--list .widget--list_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFF;
  margin-top: 10px;
  padding: 12px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.report.widgets--report .chart {
  background-color: #FFF;
  margin-top: 10px;
  padding: 12px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.report.budget {
  background-color: #FFF;
  margin: 12px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.report.budget .actions {
  margin-left: 12px;
}

.budget.upload {
  background-color: #FFF;
  margin: 12px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.chat--container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background-color: #FFF;
  margin: 12px;
  border-radius: 12px;
}
.chat--container .chat--header {
  font-weight: 600;
  padding: 12px;
  border-bottom: 1px solid #dfdfdf;
}
.chat--container .chat--messages_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}
.chat--container .chat--messages_wrapper > div {
  border-bottom: 1px solid #dfdfdf;
}

.message--item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
}
.message--item:target {
  background-color: #7ce681;
}
.message--item.deleted {
  background-color: #ed5353;
}
.message--item + .message--item {
  margin-bottom: 20px;
}
.message--item > .message--column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message--item > .message--column.avatar {
  width: 80px;
  max-width: 80px;
}
.message--item > .message--column.info {
  flex-direction: row;
  align-items: center;
}
.message--item > .info {
  padding-left: 20px;
}
.message--item > .info > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.message--item > .info > div.actions {
  max-width: 48px;
  padding: 0 8px;
}
.message--item > .info > div.actions > button + button {
  margin-top: 10px;
}
.message--item > .info .user--name {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.message--item > .info .user--name .name {
  color: #020202;
  font-weight: 600;
  margin-right: 10px;
}
.message--item .message-reply {
  margin: 10px;
  background-color: #e2e2e2;
  padding: 12px;
  cursor: pointer;
  border-left: 4px solid #00152D;
}
.message--item .message-reply .user--name {
  color: #020202;
  font-weight: 600;
  margin-right: 10px;
}

.chat--table .deleted {
  background-color: rgba(255, 0, 0, 0.3);
}

.contacts__view {
  padding: 12px;
}

.contacts--filter {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contacts--filter > button {
  margin-right: 12px;
}

.contacts--list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px 12px;
  margin: 12px 0;
}
.contacts--list .cvalues {
  margin-left: 12px;
}
.contacts--list .clist {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 12px;
}
.contacts--list .contact--item {
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.contacts--list .contact--item .photo {
  width: 180px;
  height: 180px;
  background-size: cover;
  background-position: center;
}
.contacts--list .contact--item .item__main--info {
  flex: 1;
  flex-direction: column;
  padding: 0 12px 6px;
}
.contacts--list .contact--item .item__main--info .tags {
  margin-top: 12px;
}
.contacts--list .contact--item .item__main--info .item__wrapper {
  flex: 1;
  flex-direction: column;
}
.contacts--list .contact--item .item__main--info .title {
  font-size: 1.4rem;
  font-weight: 500;
}
.contacts--list .contact--item .item__main--info .lead {
  color: #101010;
  margin-bottom: 8px;
}
.contacts--list .contact--item .item__main--info .comment {
  color: grey;
  font-size: 12px;
  margin-bottom: 8px;
}
.contacts--list .contact--item .item__actions {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.contacts--list .contact--item .item__actions button + button {
  margin-top: 12px;
}

.contact__block--row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact__block--row .ant-form-item-control, .contact__block--row .ant-form-item {
  width: 100%;
}
.contact__block--row .ant-form-item-children {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.contact__block--row .ant-form-item-children .ant-select {
  min-width: 230px;
  max-width: 230px;
}
.contact__block--row .ant-form-item-children input {
  width: 100%;
}
.contact__block--row .ant-form-item-children > * + * {
  margin-left: 20px;
}

.contacts__form--block {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  margin-bottom: 20px;
}

.widget--sortable__item {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.widget--sortable__item .sortable__item--container {
  flex: 1;
}
.widget--sortable__item .sortable__item--drag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}
.widget--sortable__item .sortable__item--drag div {
  background-color: #FFF;
  padding: 4px 6px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.logo {
  background-color: transparent;
  background-image: url("/images/logos/books.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: visible;
}

.app_view {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}
.app_view .app_view--list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.app_view .app_view--list .list .ant-empty {
  margin: 20px auto;
}
.app_view .app_view--filter {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  margin-right: 12px;
  padding: 12px;
  position: relative;
}
.app_view .pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.app_view_table {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.app_view_table .app_view--wrapper {
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 8px;
}
.app_view_table .app--filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app_view_table .app--filter > div + div {
  margin-left: 8px;
}
.app_view_table .app--filter > .search--col {
  width: 100%;
}

.app_add--view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.app_add--view .app_add--wrapper {
  background: #FFF;
  padding: 8px;
  border-radius: 4px;
}

.preview--container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  margin: 20px auto;
  background-color: #FFF;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}
.preview--container .preview--summary {
  margin-bottom: 20px;
}
.preview--container > img {
  width: 100%;
  height: auto;
  border: 0;
  margin-bottom: 20px;
}
.preview--container .preview--cover {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}
.preview--container .preview--block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.preview--container .preview--block.video, .preview--container .preview--block.image {
  flex-direction: column;
  font-size: 0;
}
.preview--container .preview--block.video img, .preview--container .preview--block.video video, .preview--container .preview--block.image img, .preview--container .preview--block.image video {
  width: 100%;
  height: auto;
}
.preview--container .preview--block.video .info, .preview--container .preview--block.image .info {
  background-color: #e2e2e2;
  padding: 12px;
  font-size: 14px;
}

.widget_view {
  background-color: #FFF;
  padding: 12px;
}
.widget_view .widget--search {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.widget_view .widget--search > button {
  margin-right: 12px;
}

.widget_view_edit-actions {
  padding: 0 12px;
}

.archive--view, .comments--view {
  margin: 12px;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.layout.push {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  margin: 12px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.layout.stats {
  display: flex;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
}
.layout.stats .push--stats__container {
  display: flex;
  flex-direction: row;
}
.layout.stats .push--stats__container > .push--stat {
  background-color: #FFF;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  flex: 1;
  flex-direction: column;
}
.layout.stats .push--stats__container > .push--stat + div {
  margin-left: 12px;
}

.tts_controller {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
.tts_controller .stt_item {
  background: #FFF;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tts_controller .stt_item > div {
  flex: 1;
  padding: 0 10px;
}
.tts_controller .stt_item + .stt_item {
  margin-top: 10px;
}
.tts_controller .controller__wrapper {
  background: #FFF;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}
.tts_controller .form__row {
  display: flex;
  flex-direction: row;
}
.tts_controller .form__row > * {
  flex: 1;
}
.tts_controller .form__row > * + * {
  margin-left: 24px;
}

.converter {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
}
.converter .converter_table {
  background: #FFF;
  border-radius: 12px;
  overflow: hidden;
}

.ant-alert-error {
  background-color: #FF0000;
  border: 1px solid #ffa39e;
  color: #FFF;
}

.material--preview {
  display: flex;
  flex-direction: column;
}
.material--preview .btn--group {
  margin-bottom: 12px;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.media--view .media__item a.preview > div.image--expired, .media--list_item .image--expired {
  position: absolute;
  top: 28px;
  left: 4px;
  margin-left: 0 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f50;
}

.media--list_item .image--expired {
  top: 4px;
}

.categories__report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.categories__report .categories__report--item {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-align: center;
  padding: 8px 12px;
}
.categories__report .categories__report--item .category__title {
  font-weight: 600;
  font-size: 16px;
}
.categories__report .categories__report--item + .categories__report--item {
  margin-left: 20px;
}

.preview--container .preview--btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.preview--container .preview--container__iframe {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  transition: width 0.3s ease 0s;
  margin: 0 auto;
}
.preview--container .preview--container__iframe iframe {
  border: 0;
  width: 100%;
  height: 800px;
  margin: 0 auto;
}

.lg_input_text {
  display: flex;
  flex-direction: row;
}
.lg_input_text + .lg_input_text {
  margin-top: 8px;
}
.lg_input_text .ant-avatar {
  margin-right: 8px;
}

.form_upload__item {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 12px;
}
.form_upload__item .input--label {
  margin-bottom: 12px;
}
.form_upload__item + .form_upload__item {
  margin-top: 12px;
}
.form_upload__item .fields, .form_upload__item .lg_input_file {
  display: flex;
  flex-direction: row;
}
.form_upload__item .fields .ant-avatar, .form_upload__item .lg_input_file .ant-avatar {
  margin-right: 8px;
}
.form_upload__item .fields + .lg_input_file, .form_upload__item .lg_input_file + .lg_input_file {
  margin-top: 8px;
}
.form_upload__item .input--wrap {
  width: 100%;
}
.form_upload__item .media_input--wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.form_upload__item .media_input--wrapper input {
  width: 100%;
}

.file--control__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 4px;
  border-radius: 6px;
  background: #FFF;
}
.file--control__input .file--name {
  padding-right: 10px;
}

.cover.transcoder {
  position: relative;
}

.transcoder__progress {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.transcoder__progress .transcode__percent {
  font-size: 2rem;
  font-weight: 600;
}

.videojs {
  width: 100%;
}
.videojs .videojs-player, .videojs .videojs-player video, .videojs .video-js {
  width: 100%;
}