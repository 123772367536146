


  .logo {
    background-color: transparent;
    background-image: url('/images/logos/books.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
